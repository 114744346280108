import { Flex, Spinner } from "@chakra-ui/react";

interface OwnProps {
  color?: string;
  noMargin?: boolean;
}

export default function CenteredLoader(props: OwnProps): JSX.Element {
  const { noMargin, color } = props;
  return (
    <Flex
      marginTop={noMargin ? undefined : "150px"}
      justifyContent="center"
      alignItems="center"
    >
      <Spinner size="lg" color={color || "brand.Branding"} />
    </Flex>
  );
}
