import { encodeToCamelCase } from "./formatForServer";
import { setupHeaders } from "./setupHeaders";
import { ContentType, MethodType, ResponseStatus } from "./types";

export interface APIParams {
  endpoint: string; // Or a list of the endpoints in an enum?
  method?: MethodType; // defaults 'GET'
  body?: string | FormData | null; // defaults 'undefined'
  contentType?: ContentType; // defaults 'application/JSON'
  customHeaders?: HeadersInit;
  noTranslateToCamel?: boolean;
}

export interface APIFail {
  status: ResponseStatus.ERROR;
  statusCode: number;
  error: string; // any?
  headers?: Headers;
}

export interface APISuccess<T> {
  status: ResponseStatus.SUCCESS;
  statusCode: number;
  body: T;
  headers?: Headers;
}

// T being the type of your expected successful response data
export type APIResponseUnion<T> = APIFail | APISuccess<T>;
export type APIResponse<T> = Promise<APIResponseUnion<T>>;

/**
 * Api helper function - calls our Express Backend
 * @returns Success: ResponseStatus.Status & Caller's Generic "body" type
 * @returns Error: ResponseStatus.Error & error message, or
 */
export async function call<T>(params: APIParams): Promise<APIResponseUnion<T>> {
  const {
    endpoint,
    method,
    body,
    contentType,
    customHeaders,
    noTranslateToCamel,
  } = params;
  const headers = await setupHeaders({
    contentType: contentType,
    ctx: undefined,
    customHeaders,
    unAuthOnly: undefined,
  }).catch(() => {
    return {
      error: "Error setting up headers",
    };
  });
  if (headers.error) {
    console.error("Error setting up headers");
    return {
      error: "Internal error with token and headers setup",
      status: ResponseStatus.ERROR,
      statusCode: 501,
    };
  }

  const options = {
    body: body ?? undefined,
    headers,
    method: method ?? MethodType.GET,
  };

  const result = await fetch(endpoint, options);
  if (!result.ok) {
    const { error, message } = await result.json();
    return {
      error: error ? (error as string) : (message as string),
      headers: result.headers,
      status: ResponseStatus.ERROR,
      statusCode: result.status,
    };
  }
  const safeRes = await getSafeRes<T>(result, noTranslateToCamel ?? false);
  return {
    body: safeRes,
    headers: result.headers,
    status: ResponseStatus.SUCCESS,
    statusCode: result.status,
  };
}

/**
 * NOTE: This catch is still a known successful call
 * This getSafeRes function safely does res.json() to account
 * for a success response with empty body
 */
async function getSafeRes<T>(
  res: Response,
  noTranslateToCamel: boolean
): Promise<T> {
  try {
    const result = await res.json();
    if (noTranslateToCamel) {
      return result as T;
    }
    const formatted = encodeToCamelCase(result) as T;
    return formatted;
  } catch {
    return {} as T;
  }
}
